<template>
  <div class="checkOut t-left">
    <h2>Ticketkauf</h2>
    <p style="font-size: 0.8em; margin-top: 8px; margin-bottom: 16px;">
      Bitte beachte, dass es vorkommen kann, dass die Tickets im Spam deines E-Mail-Postfaches landen. Bei Fragen und Problemen kannst du dich jederzeit an info@stadtmuehle.club wenden.
    </p>
    <div class="ticket-headline">
      <p class="date">
        {{ eventStart }}
      </p>
      <p class="title">
        {{ item.ticketTitle }}
      </p>
    </div>
    <div style="clear: both;">
      <div class="numberOfTickets flex" style="float: right;">
        <h3>Anzahl</h3>
        <input type="number" min="1" :max="item.available" v-model="quantity">
      </div>
    </div>
    <div style="clear: both" v-if="item.ticketInsurance">
      <p style="font-size: 0.8em; margin-top: 8px; margin-bottom: 16px; color: #ffffff;">
        <b style="font-weight: bold; color: #ffffff;">Wir empfehlen die Ticketversicherung!</b><br>
        Die Tickets sind gebucht und es kommt etwas unvorhergesehenes dazwischen? Mit der Ticketversicherung erhältst du den Ticketpreis von uns zurück, wenn du nicht am Event teilnehmen kannst.
        <br>Weitere Informationen findest du in unseren <a style="color: #009de0;" href="https://submit.paperless.io/f/KFSVCpBEukmVUqd81fKm7pv8A1RvXiQ5" target="_blank">AGB</a>.
      </p>
      <div class="">
        <input id="isInsurance" type="checkbox" v-model="isInsurance">
        <label for="isInsurance" style="margin-left: 8px;">Ticketversicherung für alle Tickets abschließen (+{{ (item.ticketInsurancePrice * quantity).toFixed(2) }}€)</label>
      </div>
    </div>
    <div style="clear: both;" />
    <div v-if="item.personal" class="person-wrapper">
      Vor- und Nachname
      <div v-for="(item2, index) in personal" :key="index" class="flex checkOutPerson">
        {{ index + 1 }}.
        <div class="flex checkOutPersonInput">
          <input type="text" placeholder="Vorname" v-model="personal[index].firstname">
          <input type="text" placeholder="Nachname" v-model="personal[index].lastname">
        </div>
      </div>
    </div>
    <hr class="separator">
    <div class="t-right">
      <p>Preis Ticket: {{ item.price.toFixed(2) }}€</p>
      <div v-if="item.feeType === 'ticket'">
        Gebühr pro Ticket: {{ item.fee.toFixed(2) }}€
      </div>
      <div v-if="isInsurance">
        Ticketversicherung: {{ (item.ticketInsurancePrice * quantity).toFixed(2) }}€
      </div>
      <p>Anzahl: {{ quantity }}</p>
      <div v-if="item.feeType === 'order'">
        Gebühr pro Bestellung: {{ item.fee.toFixed(2) }}€
      </div>
      <p>Zwischensumme: {{ subTotal.toFixed(2) }}€</p>
    </div>
    <hr class="separator">
    <coupon :items="orderItems" @coupon="c => coupon = c" />
    <payment-type :amount="price.netto" @total="credits => {useCredits = credits}" />
    <hr class="separator">
    <p style="font-size: 0.8em;">
      Mit dem klicken auf "Kaufen" bestätige ich, dass ich die <a class="color-blue" href="https://stadtmuehle.club/agb/" target="_blank">AGB</a> gelesen habe und diese akzeptiere.
    </p>
    <div style="margin: 6px 0">
      <p class="smallHint" v-if="!readyStateIntern" style="margin-bottom: 8px;">
        Bitte alle Felder ausfüllen
      </p>
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <button class="button-primary" type="button" @click="checkOut" :disabled="!readyStateIntern" :class="{disabled: !readyStateIntern}">
        Kaufen
      </button>
      <button class="bgcolor-black button color-white fluid" style="margin-top: 16px; margin-bottom: 80px;" @click="$emit('close')">
        Abbrechen
      </button>
    </div>
  </div>
  <loader size="small" v-if="loading" />
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import Coupon from "@/components/payment/coupon";
import PaymentType from "@/components/payment/paymentType";

export default {
  name: "tickets",
  components: {Loader, Coupon, PaymentType},
  props: {
    item: {
      type: Object,
      required: true
    },
    readyState: {
      type: Boolean,
      required: true
    },
    userInfo: {
      type: Object,
      required: false
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      loading: false,
      coupon: {},
      useCredits: 0,
      quantity: 1,
      isInsurance: false,
      personal: [{
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
      }],
      error: ''
    }
  },
  computed: {
    orderItems(){
      return {
        tickets: {
          [this.item.id]: {
            quantity: this.quantity,
            price: this.item.price
          }
        }
      }
    },
    subTotal(){
      if(this.item.feeType === 'ticket'){
        return (this.item.price + this.item.fee + (this.isInsurance ? this.item.ticketInsurancePrice : 0)) * this.quantity
      }
      if(this.item.feeType === 'order'){
        return (this.item.price + (this.isInsurance ? this.item.ticketInsurancePrice : 0)) * this.quantity + this.item.fee
      }
      return (this.item.price + (this.isInsurance ? this.item.ticketInsurancePrice : 0)) * this.quantity
    },
    price(){
      const price = {}
      price.brutto = this.subTotal
      price.discount = 0

      if(this.coupon.discount){
        price.discount = this.coupon.discount
      }

      price.discount = price.discount.toFixed(2)
      price.netto = (Math.round(Math.max(0, price.brutto - price.discount) * 100) / 100).toFixed(2)
      return price
    },
    eventStart(){
      return dateToString(stringToDate(this.item.startTime))
    },
    readyStateIntern(){
      if(this.item.personal){
        let ready = true
        this.personal.forEach(p => {
          if(!p.firstname.length || !p.lastname.length) ready = false
        })
        return this.readyState && ready
      }else{
        return this.readyState
      }
    }
  },
  watch: {
    quantity(){
      if(this.personal.length < this.quantity){
        do {
          this.personal[this.personal.length] = {firstname: '', lastname: ''}
        }while (this.personal.length < this.quantity)
      }else if(this.personal.length > this.quantity){
        this.personal.splice(this.quantity, this.personal.length - this.quantity)
      }
    }
  },
  mounted() {
  },
  methods: {
    checkOut(){
      this.loading = true
      this.error = ''
      aSyncData('checkout/tickets', {
        email: this.userInfo.email,
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
        phone: this.userInfo.phone,
        userInfo: this.personal,
        couponId: this.coupon.id ?? null,
        currentSite: this.$route.path,
        items: {tickets: [{id: this.item.id, quantity: this.quantity, insurance: this.isInsurance}]},
        subTotal: this.subTotal,
        total: this.price.netto,
        useCredits: this.useCredits,
        isInsurance: this.isInsurance,
        payPal: this.price.netto - this.useCredits/100
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }
            else if(r.status === 295) {
              this.auth.update()
                  .catch(e => {})
              this.error = 'Bitte erneut einlogen oder Email angeben'
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
  .smallHint{
    font-size: 0.8em;
  }
  .disabled,
  .disabled:hover{
    background-color: gray !important;
  }

  .checkOut h2{
    margin: 10px 0;
    font-weight: 400;
    font-size: 1.3em;
  }

  .ticket-headline{

  }

  .ticket-headline .title{
    color: #009de0 !important;
    font-size: 1.2em;
    margin: 4px 0 8px 0;
    font-weight: 600;
  }

  .ticket-headline .date {
    font-size: 0.8em;
  }

  .ticket-headline .description {
    font-size: 1em;
  }

  .numberOfTickets{
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    margin: 10px 0;
  }

  .numberOfTickets input{
    padding: 8px;
    width: 20%;
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
  }

  .person-wrapper{
    margin: 20px 0;
  }

  .person-wrapper input{
    padding: 8px;
    width: 80%;
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
  }

  .checkOutPerson{
    column-gap: 12px;
    margin: 6px 0 16px;
  }

  .checkOutPersonInput{
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }

  .button-primary{
    width: 100%;
    margin-top: 12px;
    text-transform: uppercase !important;
  }

</style>